<template>
  <div>
    <div class="d-flex mb-16">
      <h1 class="headline--md text-bold text-uppercase">Gold investment program</h1>

      <div v-if="currentUser && $auth('Page', currentUser, $accessRule.ProgramUpdate).canView()" class="d-flex">
        <div class="d-flex align-start form-switch-wrapper">
          <p class="mr-4">Go live on app:</p>
          <label class="form-switch">
            <input type="checkbox" v-model="programStatus" @click.prevent="onConfirmToggleStatusOpen = true" />
            <span></span>
          </label>
        </div>

        <router-link :to="{ name: 'GoldInvestmentEdit' }" class="btn btn--primary">Edit Program</router-link>
      </div>
    </div>

    <div v-if="goldInvestmentInfo" class="box-group">
      <div class="box box--lg box--white">
        <div class="statistics align-start">
          <div class="statistic flex-basis-0">
            <p class="statistic__title text-warm">Background image</p>
            <img :src="goldInvestmentInfo.image_url" width="216" alt="gold investment background" />
          </div>

          <div class="statistic flex-basis-0">
            <p class="statistic__title text-warm">Min. Withdraw<br />Gold Amount (gram)</p>
            <h3 class="headline--md">{{ settings && settings.minWithdrawGoldAmount }}</h3>
          </div>
        </div>

        <div class="statistics align-start mt-48">
          <div class="statistic flex-basis-0">
            <p class="statistic__title text-warm">Markup Fee Reduction</p>
            <h3 class="headline--md">
              {{ settings && settings.rateReduce }}% - TOKO
              {{ settings && settings.tokoDepositReduce | float_number_with_commas }}
            </h3>
          </div>

          <div class="statistic flex-basis-0">
            <p class="statistic__title text-warm">Withdraw Fee (TOKO)</p>
            <h3 class="headline--md">{{ settings && settings.withdrawFee | number_with_commas }}</h3>
          </div>
        </div>
      </div>

      <div class="box box--white no-pad">
        <h2 class="headline--md text-bold box__head">Markup Fee</h2>
        <div class="divider"></div>
        <div class="table-wrapper table-wrapper--fluid table-wrapper--expand">
          <table v-if="showGoldInvestmentMarkupFees" class="table table--striped table--setting">
            <thead>
              <tr>
                <th>Gold Amount (gram)</th>
                <th>Markup Fee (%)</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in goldInvestmentMarkupFees" :key="item.id">
                <td>{{ item.gold_weight }}</td>
                <td>{{ item.fee }}</td>
              </tr>
            </tbody>
          </table>

          <p v-else class="text-center mt-16">Not markup fees setting yet!</p>
        </div>
      </div>
    </div>

    <div class="box box--white mt-24 pb-0">
      <h2 class="headline--md text-bold mb-16">Requirements of Joining Program</h2>
      <div class="divider"></div>
      <div class="table-wrapper table-wrapper--fluid">
        <table v-if="showGoldInvestmentRequirements" class="table table--white">
          <thead>
            <tr>
              <th>Conditions</th>
              <th>Min. Staked<br />TOKO</th>
              <th>Min. Buying and<br />Investing Gold (USDT)</th>
              <th>Max. Buying and<br />Investing Gold (USDT)</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in goldInvestmentRequirements" :key="item.id">
              <td>Condition {{ ++index }}</td>
              <td>{{ item.minimum_toko_deposited | number_with_commas }}</td>
              <td>{{ item.minimum_buying_usdt | float_number_with_commas }}</td>
              <td>{{ item.maximum_buying_usdt | float_number_with_commas }}</td>
            </tr>
          </tbody>
        </table>

        <p v-else class="text-center mt-16">Not requirements setting yet!</p>
      </div>
    </div>

    <confirm-dialog
      v-if="onConfirmToggleStatusOpen"
      :open.sync="onConfirmToggleStatusOpen"
      confirmButtonText="Save"
      confirmButtonClass="text-purple"
      @confirm="onUpdateProgram"
    >
      <p v-if="programStatus">Are you sure to disable this program ?</p>
      <p v-else>Are you sure to enable this program ?</p>
    </confirm-dialog>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { deepCopy } from '@/utils/json-parser'
import { GOLD_INVESTMENT_SETTINGS } from '@/utils/key-mapping'
import toggleLoading from '@/mixins/toggle-loading'
import formatActivate from '@/mixins/format-activate'
import updateProgram from './mixins/update-program'
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      programStatus: true,
      onConfirmToggleStatusOpen: false,
      goldInvestmentSettingKeys: GOLD_INVESTMENT_SETTINGS,
      settings: {}
    }
  },

  mixins: [updateProgram, formatActivate, toggleLoading],

  computed: {
    ...mapState('goldInvestment', [
      'goldInvestmentInfo',
      'goldInvestmentSettings',
      'goldInvestmentRequirements',
      'goldInvestmentMarkupFees'
    ]),

    showGoldInvestmentRequirements() {
      return this.goldInvestmentRequirements && this.goldInvestmentRequirements.length
    },

    showGoldInvestmentMarkupFees() {
      return this.goldInvestmentMarkupFees && this.goldInvestmentMarkupFees.length
    }
  },

  components: {
    ConfirmDialog
  },

  methods: {
    ...mapActions('goldInvestment', [
      'getGoldInvestmentSettings',
      'getGoldInvestmentRequirements',
      'getGoldInvestmentMarkupFees'
    ]),

    getProgramDataUpdate() {
      return {
        id: this.goldInvestmentInfo.id,
        status: this.programStatus ? this.status.deactive : this.status.active
      }
    },

    onUpdateProgram() {
      this.toggleLoading()
      this.updateProgram(this.getProgramDataUpdate())
        .then(() => {
          this.programStatus = this.goldInvestmentInfo.status === this.status.active
        })
        .finally(() => {
          this.onConfirmToggleStatusOpen = false
          this.toggleLoading()
        })
    },

    getSettingData() {
      const settingsData = deepCopy(this.goldInvestmentSettings)
      for (let i = 0; i < settingsData.length; i++) {
        switch (settingsData[i].key) {
          case this.goldInvestmentSettingKeys.tokoDepositReduce:
            this.settings.tokoDepositReduce = settingsData[i].value
            break
          case this.goldInvestmentSettingKeys.rateReduce:
            this.settings.rateReduce = settingsData[i].value
            break
          case this.goldInvestmentSettingKeys.minWithdrawGoldAmount:
            this.settings.minWithdrawGoldAmount = settingsData[i].value
            break
          case this.goldInvestmentSettingKeys.withdrawFee:
            this.settings.withdrawFee = settingsData[i].value
            break
        }
      }
    }
  },

  async created() {
    try {
      await this.getProgramList()
      await this.getGoldInvestmentSettings()
      await this.getGoldInvestmentMarkupFees({ order_by: 'asc' })
      await this.getGoldInvestmentRequirements({ order_by: 'asc' })
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.programStatus = this.goldInvestmentInfo.status === this.status.active
    this.getSettingData()
    this.toggleLoading()
  }
}
</script>
